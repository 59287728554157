import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialState = {
    messageNotify: boolean;
    messageInfo: string;
    messageType: string;
}

const initialState: initialState = {
    messageNotify: false,
    messageInfo: "",
    messageType: "",
   
}

const AlertMessageSlice = createSlice({
    name: 'AlertMessage',
    initialState,
    reducers: {
        setMessageInfo: (
            state: initialState,
            action: PayloadAction<string>
        ) => {
            state.messageInfo = action.payload;
        },
        setMessageNotify: (
            state: initialState,
            action: PayloadAction<boolean>
        ) => {
            state.messageNotify = action.payload;
        },
        setMessageType: (
            state: initialState,
            action: PayloadAction<string>
        ) => {
            state.messageType = action.payload;
        }

    }
})

export default AlertMessageSlice.reducer

export const {
    setMessageNotify,
    setMessageType,
    setMessageInfo,
} = AlertMessageSlice.actions;