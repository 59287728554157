import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const SourceOfinfoApi = createApi({
    reducerPath: 'SourceOfinfoApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }    }),
    tagTypes: ['sourceOfinfo'],
    endpoints: (builder) => ({
        createSourceOfinfo: builder.mutation<any, any>({
            query: (body: any) => ({
                url: urlConstants.SOURCE_OF_INFO_CREATE,
                method: 'POST',
                body: body

            }), invalidatesTags: ['sourceOfinfo']
        }),
      
        getSourceOfinfoList:builder.query<any, void>({
            query: () => ({
                url: urlConstants.SOURCE_OF_INFO_LIST,
                method: 'GET',
            }),
            providesTags: ['sourceOfinfo']
        }), 
        editSourceOfinfo: builder.mutation<any, any>({
            query: ({body,id}:any) => ({
                url: `${urlConstants.SOURCE_OF_INFO}/${id}`,
                method: 'PUT',
                body: body

            }), invalidatesTags: ['sourceOfinfo']
        }),
        getSingleSourceOfinfo: builder.query<any,any>({
            query: (id:any) => ({
                url:  `${urlConstants.SOURCE_OF_INFO}/${id}`,
                method: 'GET',
            }),
            providesTags: ['sourceOfinfo']
        }), 
         deleteSourceOfinfo: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.SOURCE_OF_INFO}/${id}`,
                method: 'DELETE',

            }), invalidatesTags: ['sourceOfinfo']
        }),
      
    })

})
export const {
    useCreateSourceOfinfoMutation,
    useGetSingleSourceOfinfoQuery,
    useGetSourceOfinfoListQuery,
    useEditSourceOfinfoMutation,
    useDeleteSourceOfinfoMutation
} = SourceOfinfoApi