import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const BudgetApi = createApi({
    reducerPath: 'BudgetApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }    }),
    tagTypes: ['budget'],
    endpoints: (builder) => ({
        createBudget: builder.mutation<any, any>({
            query: (body: any) => ({
                url: urlConstants.BUDGET_RANGE_CREATE,
                method: 'POST',
                body: body

            }), invalidatesTags: ['budget']
        }),
      
        getBudgetList:builder.query<any, void>({
            query: () => ({
                url: urlConstants.BUDGET_RANGE_LIST,
                method: 'GET',
            }),
            providesTags: ['budget']
        }), 
        editBudget: builder.mutation<any, any>({
            query: ({body,id}:any) => ({
                url: `${urlConstants.BUDGET_RANGE}/${id}`,
                method: 'PUT',
                body: body

            }), invalidatesTags: ['budget']
        }),
        getSingleBudget: builder.query<any,any>({
            query: (id:any) => ({
                url:  `${urlConstants.BUDGET_RANGE}/${id}`,
                method: 'GET',
            }),
            providesTags: ['budget']
        }), 
         deleteBudget: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.BUDGET_RANGE}/${id}`,
                method: 'DELETE',

            }), invalidatesTags: ['budget']
        }),
      
    })

})
export const {
    useCreateBudgetMutation,
    useGetSingleBudgetQuery,
    useGetBudgetListQuery,
    useEditBudgetMutation,
    useDeleteBudgetMutation
} = BudgetApi