import { configureStore, combineReducers } from '@reduxjs/toolkit'
import errorMessageReducer from '../reducer/errorMessageReducer';
import { loginApi } from '../../services/apiService/login/login';
import alertReducer from '../reducer/alertReducer';
import { AquisitionApi } from '../../services/apiService/acquisitionForm/acquisitionForm';
import { UsersApi } from '../../services/apiService/users/users';
import { RoleApi } from '../../services/apiService/roles/roles';
import { DepartmentApi } from '../../services/apiService/department/department';
import { BudgetApi } from '../../services/apiService/budget/budgetRange';
import { AgeApi } from '../../services/apiService/ages/ageService';
import { OccupationApi } from '../../services/apiService/occupation/occupation';
import { SourceOfinfoApi } from '../../services/apiService/sourceOfInfo/sourceOfInfo';
import { ProjectApi } from '../../services/apiService/projects/project';
import { PermissionApi } from '../../services/apiService/permissions/rolePermissions';
import { PreferredUnitApi } from '../../services/apiService/preferredUnit/preferredUnit';
import PreferredSizeAdd from '../../pages/preferredSize/preferredSizeAdd/preferredSizeAdd';
import { PreferredSizeApi } from '../../services/apiService/preferredSize/preferredSize';
import { PurposeOfPurchaseApi } from '../../services/apiService/purposeOfPurchase/purposeOfPurchase';
import { FieldEmployeesApi } from '../../services/apiService/fieldEmployees/fieldEmployees';
import { profileApi } from '../../services/apiService/profile/profile';
import { DashboardApi } from '../../services/apiService/dashboard/dashboard';
import { ReportApi } from '../../services/apiService/report/report';
import {ReviewModelApi} from '../../services/apiService/customerReview/customerReview'
const rootReducers = combineReducers({
    ErrorMessageReducer: errorMessageReducer,
    alertReducer: alertReducer,
    loginApi: loginApi.reducer,
    AquisitionApi: AquisitionApi.reducer,
    UsersApi: UsersApi.reducer,
    RoleApi: RoleApi.reducer,
    DepartmentApi: DepartmentApi.reducer,
    BudgetApi: BudgetApi.reducer,
    AgeApi: AgeApi.reducer,
    OccupationApi: OccupationApi.reducer,
    SourceOfinfoApi: SourceOfinfoApi.reducer,
    ProjectApi: ProjectApi.reducer,
    PermissionApi: PermissionApi.reducer,
    PreferredUnitApi: PreferredUnitApi.reducer,
    PreferredSizeApi: PreferredSizeApi.reducer,
    PurposeOfPurchaseApi: PurposeOfPurchaseApi.reducer,
    FieldEmployeesApi: FieldEmployeesApi.reducer,
    profileApi: profileApi.reducer,
    DashboardApi: DashboardApi.reducer,
    ReportApi: ReportApi.reducer,
    ReviewModelApi: ReviewModelApi.reducer
})

const store = configureStore({
    reducer: rootReducers,
    middleware: (getdefaultMiddleware) => getdefaultMiddleware().concat([
        loginApi.middleware,
        profileApi.middleware,
        AquisitionApi.middleware,
        UsersApi.middleware,
        RoleApi.middleware,
        DepartmentApi.middleware,
        ProjectApi.middleware,
        BudgetApi.middleware,
        AgeApi.middleware,
        OccupationApi.middleware,
        SourceOfinfoApi.middleware,
        PermissionApi.middleware,
        PreferredUnitApi.middleware,
        PreferredSizeApi.middleware,
        PurposeOfPurchaseApi.middleware,
        FieldEmployeesApi.middleware,
        DashboardApi.middleware,
        ReportApi.middleware,
        ReviewModelApi.middleware
    ])
})
export default store

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;