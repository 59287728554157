import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const OccupationApi = createApi({
    reducerPath: 'OccupationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }    }),
    tagTypes: ['occupation'],
    endpoints: (builder) => ({
        createOccupation: builder.mutation<any, any>({
            query: (body: any) => ({
                url: urlConstants.OCCUPATION_CREATE,
                method: 'POST',
                body: body

            }), invalidatesTags: ['occupation']
        }),
      
        getOccupationList:builder.query<any, void>({
            query: () => ({
                url: urlConstants.OCCUPATION_LIST,
                method: 'GET',
            }),
            providesTags: ['occupation']
        }), 
        editOccupation: builder.mutation<any, any>({
            query: ({body,id}:any) => ({
                url: `${urlConstants.OCCUPATION}/${id}`,
                method: 'PUT',
                body: body

            }), invalidatesTags: ['occupation']
        }),
        getSingleOccupation: builder.query<any,any>({
            query: (id:any) => ({
                url:  `${urlConstants.OCCUPATION}/${id}`,
                method: 'GET',
            }),
            providesTags: ['occupation']
        }), 
         deleteOccupation: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.OCCUPATION}/${id}`,
                method: 'DELETE',

            }), invalidatesTags: ['occupation']
        }),
      
    })

})
export const {
    useCreateOccupationMutation,
    useGetSingleOccupationQuery,
    useGetOccupationListQuery,
    useEditOccupationMutation,
    useDeleteOccupationMutation
} = OccupationApi