
import { Spinner } from 'react-bootstrap'
import './loader.scss'

function Index() {
  return (
    <>
          <div className='custom-content-loader'>
          <Spinner animation="grow" variant="secondary" size="sm" className='mx-1'/>
          <Spinner animation="grow" variant="secondary" size="sm" className='mx-1'/>
          <Spinner animation="grow" variant="secondary" size="sm" className='mx-1'/>
          </div>
    </>
  )
}

export default Index