import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const UsersApi = createApi({
    reducerPath: 'UsersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }
    }),
    tagTypes: ['user', 'userDropdowns'],
    endpoints: (builder) => ({
        createUser: builder.mutation<any, any>({
            query: (userData: any) => ({
                url: urlConstants.CREATE_USER,
                method: 'POST',
                body: userData

            }), invalidatesTags: ['user']
        }),
        getUserDropdownsDetails: builder.query<any, void>({
            query: () => ({
                url: urlConstants.USER_DROPDOWNS,
                method: 'GET',
            }),
            providesTags: ['userDropdowns']
        }),
        getUserList: builder.query<any, any>({
            query: (searchPrams:any) => ({
                url: urlConstants.USERS_LIST,
                method: 'GET',
                params:{
                    page:searchPrams?.currentPage,
                    // limit:1,
                    searchString:searchPrams?.searchString
                }
            }),
            providesTags: ['user']
        }),
        editUser: builder.mutation<any, any>({
            query: ({ userData, id }: any) => ({
                url: `${urlConstants.USER}/${id}`,
                method: 'PUT',
                body: userData

            }), invalidatesTags: ['user']
        }),
        getSingleUser: builder.query<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.USER}/${id}`,
                method: 'GET',
            }),
            providesTags: ['user']
        }),
        deleteUser: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.USER}/${id}`,
                method: 'DELETE',

            }), invalidatesTags: ['user']
        }),
    })

})
export const {
    useCreateUserMutation,
    useGetSingleUserQuery,
    useGetUserListQuery,
    useEditUserMutation,
    useGetUserDropdownsDetailsQuery,
    useDeleteUserMutation
} = UsersApi