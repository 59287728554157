import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const PreferredSizeApi = createApi({
    reducerPath: 'PreferredSizeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }    }),
    tagTypes: ['preferredSize'],
    endpoints: (builder) => ({
        createPreferredSize: builder.mutation<any, any>({
            query: (body: any) => ({
                url: urlConstants.PREFERRED_SIZE_CREATE,
                method: 'POST',
                body: body

            }), invalidatesTags: ['preferredSize']
        }),
      
        getPreferredSizeList:builder.query<any, void>({
            query: () => ({
                url: urlConstants.PREFERRED_SIZE_LIST,
                method: 'GET',
            }),
            providesTags: ['preferredSize']
        }), 
        editPreferredSize: builder.mutation<any, any>({
            query: ({body,id}:any) => ({
                url: `${urlConstants.PREFERRED_SIZE}/${id}`,
                method: 'PUT',
                body: body

            }), invalidatesTags: ['preferredSize']
        }),
        getSinglePreferredSize: builder.query<any,any>({
            query: (id:any) => ({
                url:  `${urlConstants.PREFERRED_SIZE}/${id}`,
                method: 'GET',
            }),
            providesTags: ['preferredSize']
        }), 
         deletePreferredSize: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.PREFERRED_SIZE}/${id}`,
                method: 'DELETE',

            }), invalidatesTags: ['preferredSize']
        }),
      
    })

})
export const {
    useCreatePreferredSizeMutation,
    useGetSinglePreferredSizeQuery,
    useGetPreferredSizeListQuery,
    useEditPreferredSizeMutation,
    useDeletePreferredSizeMutation
} = PreferredSizeApi