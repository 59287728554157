import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const ProjectApi = createApi({
    reducerPath: 'ProjectApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }    }),
    tagTypes: ['project'],
    endpoints: (builder) => ({
        createProject: builder.mutation<any, any>({
            query: (body: any) => ({
                url: urlConstants.PROJECT_CREATE,
                method: 'POST',
                body: body

            }), invalidatesTags: ['project']
        }),
      
        getProjectList:builder.query<any, void>({
            query: () => ({
                url: urlConstants.PROJECT_LIST,
                method: 'GET',
            }),
            providesTags: ['project']
        }), 
        getSFProjectList:builder.query<any, void>({
            query: () => ({
                url: urlConstants.SF_PROJECTS,
                method: 'GET',
            }),
            providesTags: ['project']
        }), 
        
        editProject: builder.mutation<any, any>({
            query: ({body,id}:any) => ({
                url: `${urlConstants.PROJECT}/${id}`,
                method: 'PUT',
                body: body

            }), invalidatesTags: ['project']
        }),
        getSingleProject: builder.query<any,any>({
            query: (id:any) => ({
                url:  `${urlConstants.PROJECT}/${id}`,
                method: 'GET',
            }),
            providesTags: ['project']
        }), 
         deleteProject: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.PROJECT}/${id}`,
                method: 'DELETE',

            }), invalidatesTags: ['project']
        }),
      
    })

})
export const {
    useCreateProjectMutation,
    useGetSingleProjectQuery,
    useGetSFProjectListQuery,
    useGetProjectListQuery,
    useEditProjectMutation,
    useDeleteProjectMutation
} = ProjectApi