import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const PermissionApi:any = createApi({
    reducerPath: 'PermissionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }    }),
    tagTypes: ['permissions'],
    endpoints: (builder) => ({
        createPermissionRole: builder.mutation<any, any>({
            query: (body: any) => ({
                url: urlConstants.SCREENS,
                method: 'POST',
                body: body

            }), invalidatesTags: ['permissions']
        }),
      
        getScreenList:builder.query<any, void>({
            query: () => ({
                url: urlConstants.SCREENS,
                method: 'GET',
            }),
            providesTags: ['permissions']
        }), 
      
      
    })

})
export const {
   useGetScreenListQuery,
   useCreatePermissionRoleMutation
} = PermissionApi